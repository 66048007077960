
.markdown {
  white-space: pre-wrap;
}

.markdown h1 {
  font-weight: bold;
  font-size: 120%;
}

.markdown h2 {
  font-weight: bold;
  font-size: 110%;
}

.markdown h3 {
  font-weight: bold;
  font-size: 100%;
}

.markdown-body.markdown-body pre, .markdown-body.markdown-body code {
  white-space: break-spaces;
  word-wrap: break-word;
}